Application.Controller.Enterprise = (function($) {
    function edit() {
        var checker = $('#a_enterprise_externalUrl');
        if (checker.val() == 0) {
            $('#a_enterprise_externalLink').parent().hide();
        }

        $('#a_enterprise_externalUrl').on('change', function() {
            if (checker.val() == 1) {
                $('#a_enterprise_externalLink').parent().show();
                $('#a_enterprise_externalLink').attr('required', 'required');
            } else {
                $('#a_enterprise_externalLink').parent().hide();
            }
        });

        $('.btn-update, .btn-remove').on('click', function() {
            console.log($(this));
            $('form#form-remove').attr('action', $(this).data('action')).submit();
        });

        $('.js-remove-line').on('click', function(e) {
            e.preventDefault();

            var remove = $(this);
            var current = remove.closest('div').find('tbody tr');

            if (current.length > 1) {
                remove.closest('tr').remove();
            } else {
                remove.closest('tr').find(':input').val('');
            }
        });
    }

    return {
        'new': edit,
        'create': edit,
        'edit': edit,
        'update': edit,
        'editStage': edit
    };
})(jQuery);
